@import "mixins"
.prematchHeader
  composes: liveHeader from "components/pages/sport/live/header/live-header.module.sass"

.prematchFilter
  display: flex
  align-items: center
  justify-content: space-between
  padding: 1px
  border-radius: 3px
  background: var(--live_tabsBg)

  .timefilterOptions
    display: flex
    min-width: calc((100% - 10px) / 6 * 2)
    border-radius: 3px
    overflow: hidden
    margin-left: 4px
    background-color: var(--sport_timeFilterBg)

    &:has(:nth-child(3))
      min-width: calc((100% - 10px) / 6 * 3)
    > *
      line-height: 11px
    .active
      background: var(--sport_timeFilterItemActiveBg) !important
      color: var(--sport_timeFilterItemActiveColor) !important
      border-radius: 3px

  .timefilter
    @include flex
    justify-content: center
    text-align: center
    height: var(--live_tabHeight)
    padding: 0 8px
    flex: 1 100%
    color: #fff
    border: none
    background: none
    line-height: 14px

    &:disabled
      opacity: 0.25

    &:not(button).active, &.activeAll
      border-radius: var(--live_tabBorderRadius)
      box-shadow: var(--live_tabActiveShadow)

    &.active
      color: var(--live_tabLiveActiveColor)
      background: var(--live_tabLiveActiveBg)

    &.activeAll
      color: var(--live_tabActiveColor)
      background: var(--live_tabActiveBg)

.favoritesTab
  margin-left: 6px
  background: transparent
  @include flex
  flex-direction: column
  flex: 1 1 100%
  min-width: 60px
  border-radius: 3px

  .favoritesCount
    font-size: 11px
    color: rgb(var(--primaryColor), 0.6)
    margin: 0 -32px -8px 0
  .favoritesStar
    margin-left: -14px
    opacity: 0.8
  &:disabled
    opacity: 1
    svg
      filter: grayscale(100%)
      opacity: 0.5

  &.active
    background: var(--live_favoritesTabBg)
    .favoritesCount
      color: #fff
    .favoritesStar
      opacity: 1

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 900px)
  .prematchHeader
    position: unset
